import { mailApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    list(option = '') {
        return mailApi({
            method: 'get',
            url: `/api?${option}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    create(data) {
        return mailApi({
            method: 'post',
            url: `/api`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    update(id, data) {
        return mailApi({
            method: 'patch',
            url: `/api/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    delete(id) {
        return mailApi({
            method: 'delete',
            url: `/api/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    trash(){
        return mailApi({
            method: 'get',
            url: `/api/trashes`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    restore(id){
        return mailApi({
            method: 'put',
            url: `/api/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})
