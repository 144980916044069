<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Log</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field
                    disabled
                    type="text"
                    class="mt-2"
                    v-model="form.provider.name"
                    label="Subject"
                  ></v-text-field>

                  <v-text-field
                    disabled
                    type="text"
                    class="mt-2"
                    v-model="form.from"
                    label="From*"
                  ></v-text-field>

                  <v-text-field
                    disabled
                    type="text"
                    class="mt-2"
                    v-model="form.to"
                    label="To*"
                  ></v-text-field>

                  <v-text-field
                    disabled
                    type="text"
                    class="mt-2"
                    v-model="form.subject"
                    label="Subject"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Close</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash"
import {mapGetters} from "vuex"

export default {
  data() {
    return {
      form: {
        from: null,
        to: null,
        subject: null,
        provider: null
      }
    };
  },
  mounted(){
    this.form = _.pick(this.vg_editObj, ["from","to","subject", "provider"])
  },
  computed: {
      ...mapGetters({
          vg_editObj: "log/editObj"
      })
  },
  methods: {
    cancel() {
      this.$router.push({ name: "Log" });
    }
  }
};
</script>