import Log from "@/views/log";
import LogEdit from "@/views/log/edit"

export default [
    {
        name: "Log",
        path: "/log",
        component: Log,
        meta: {
            nav: {
                addToNav: true,
                navText: "Log",
                header: "Log",
                navIcon: "mdi-clipboard-text-clock",
            }
        },
    },
    {
        name: "LogEdit",
        path: "/log/edit",
        component: LogEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Log > Edit",
                navIcon: "",
            }
        }
    }
]
