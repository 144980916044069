import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import env from '@/env.js'

import login from './modules/login'
import provider from './modules/provider'
import api from './modules/api'
import log from './modules/log'
import template from './modules/template'

export const config = env[env.state];
var ls = new SecureLS({ isCompression: false, encryptionSecret: config.app_key })

Vue.use(Vuex)

export default  new Vuex.Store({
    plugins: [createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })],
    modules: {login, provider, api, template, log}
})