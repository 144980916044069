<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Log</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_log.rows"
          :loading="loading"
          :server-items-length="vg_log.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.created_at="{item}">
            {{new Date(item.created_at).toLocaleDateString("fr-CA")}}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-eye</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash"
import { mapGetters, mapMutations, mapActions } from "vuex";
import VuetifyTable from "@/helper/table"
export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Provider", value: "provider.name", sortable:false },
        { text: "From", value: "from" },
        { text: "To", value: "to" },
        { text: "Subject", value: "subject" },
        { text: "Created", value: "created_at" },
        { text: "Actions", value: "action", sortable:false },
      ],
      search: ""
    };
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          await this.va_log(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    "vg_queryObject.search"(){
      this.options.page = 1
    }
  },
  computed: {
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    ...mapGetters({
      vg_log: "log/list",
      vg_queryObject: "log/query"
    })
  },
  methods: {
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "LogEdit" })
    },
    ...mapMutations({
      vm_editObj: "log/editObj",
      vm_queryObject: "log/query"
    }),
    ...mapActions({
      va_log: "log/list"
    })
  }
};
</script>
