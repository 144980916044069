<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Template</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-select
                    class="mt-2"
                    :items="apiItems"
                    v-model="form.api_id"
                    :rules="rules.api_id"
                    label="Api id*"
                    item-text="api_name"
                    item-value="id"
                  ></v-select>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.subject"
                    :rules="rules.subject"
                    hint="Please enter subject"
                    label="Subject*"
                  ></v-text-field>

                  <v-textarea
                    name="textarea"
                    v-model="form.html"
                    :rules="rules.html"
                    label="Html*"
                  ></v-textarea>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ApiApi from '@/api/api'
import TemplateApi from "@/api/template"

export default {
  data() {
    return {
      apiItems:[],
      valid: null,
      form: {
        name: null,
        api_id: null,
        subject: null,
        html: null
      },
      rules: {
        name: [v => !!v || "Name is required"],
        api_id: [v => !!v || "Provider id is required"],
        subject: [v => !!v || "Subject is required"],
        html: [v => !!v || "Html is required"]
      }
    };
  },
  async mounted(){
    let {data} = await ApiApi.list('items=-1&page=1');
    if(data.payload?.rows){
      this.apiItems = data.payload.rows
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await TemplateApi.create(this.form)
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Template"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Template" });
    }
  }
};
</script>