import TemplateApi from '@/api/template'

export default {
    namespaced: true,
    state: {
        list: {},
        listTrash: [],
        addPop: false,
        editPop: false,
        editObj: {},
        query: {
            search: '',
            itemsPerPage: 15
        },
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        addPop: state => state.addPop,
        editPop: state => state.editPop,
        editObj: state => state.editObj,
        query: state => state.query
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        addPop(state, data) {
            state.addPop = data
            // If add is on edit is false
            state.editPop = false
        },
        editPop(state, data) {
            state.editPop = data
            // If Edit is on add is false
            state.addPop = false
        },
        editObj(state, data) {
            state.editObj = data
        },
        query(state, data) {
            state.query = data
        },
        reset(state) {
            state.list = {}
            state.listTrash = []
            state.addPop = false
            state.editPop = false
            state.editObj = {},
            state.query = {
                search: '',
                itemsPerPage: 15
            }
        }
    },
    actions: {
        async list({ commit }, queryString) {
            try{
                let {data} = await TemplateApi.list(queryString);
                commit('list', data.payload);
            }catch(err){
                commit('list', {count: 0, rows: []})
            }
        },
        async trashApp({commit}){
            try{
                let {data} = await TemplateApi.trash();
                commit('listTrash', data.payload);
            }catch(err){
                commit('listTrash', [])
            }
        }
    }
};
