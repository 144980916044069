import Provider from "@/views/provider";
import ProviderCreate from "@/views/provider/create"
import ProviderEdit from "@/views/provider/edit"
import ProviderTrash from "@/views/provider/trash"

export default [
    {
        name: "Provider",
        path: "/provider",
        component: Provider,
        meta: {
            nav: {
                addToNav: true,
                navText: "Provider",
                header: "Provider",
                navIcon: "mdi-face-agent",
            }
        },
    },
    {
        name: "ProviderCreate",
        path: "/provider/create",
        component: ProviderCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Provider > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "ProviderEdit",
        path: "/provider/edit",
        component: ProviderEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Provider > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "ProviderTrash",
        path: "/provider/trash",
        component: ProviderTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Provider > Trash",
                navIcon: "",
            }
        }
    }
]
