<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Template</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" class="mt-4" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn v-if="role.trashes"
            class="ml-4 mt-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_template.rows"
          :loading="loading"
          :server-items-length="vg_template.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.provider_id="{ item }">
            {{item.provider.name}}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash"
import { mapGetters, mapMutations, mapActions } from "vuex";
import TemplateApi from "@/api/template"
import VuetifyTable from "@/helper/table"
export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Api", value: "api.api_name", sortable:false  },
        { text: "Subject", value: "subject" },
        { text: "Actions", value: "action", sortable:false },
      ],
      search: ""
    };
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          await this.va_template(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    "vg_queryObject.search"(){
      this.options.page = 1
    }
  },
  computed: {
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Template")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_template: "template/list",
      vg_queryObject: "template/query"
    })
  },
  methods: {
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    createApi() {
      this.$router.push({ name: "TemplateCreate" })
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "TemplateEdit" })
    },
    trashedApi() {
      this.$router.push({ name: "TemplateTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm("Are you sure to delete this?").then(async () => {
        try{
          self.spinner(true)
          let {data} = await TemplateApi.delete(item.id)
          this.snackbar({status: true, message: data.message, color:"success"})
          await this.va_template(this.optionsToQueryString(this.vg_queryObject))
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "template/editObj",
      vm_queryObject: "template/query"
    }),
    ...mapActions({
      va_template: "template/list"
    })
  }
};
</script>
