<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Provider</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="form.email_id"
                    :rules="rules.email_id"
                    :items="users"
                    :loading="isLoading"
                    :search-input.sync="searchUser"
                    hide-no-data
                    hide-selected
                    item-text="email"
                    item-value="email"
                    label="Email Id*"
                    placeholder="Type email address"
                  ></v-autocomplete>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.sort"
                    :rules="rules.sort"
                    hint="Please enter sort"
                    label="Sort"
                  ></v-text-field>

                  <v-textarea
                    name="textarea"
                    v-model="form.api_key"
                    :rules="rules.api_key"
                    label="Api key*"
                  ></v-textarea>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.limit_per_day"
                    hint="Please enter limit per day"
                    label="Limit per day"
                  ></v-text-field>

                  <v-switch
                    class="mt-2"
                    v-model="form.is_active"
                    label="Is active"
                  ></v-switch>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash'
import Provider from "@/api/provider"
import User from '@/api/user'
import ObjectHelper from '@/helper/object'

export default {
  data() {
    return {
      valid: null,
      form: {
        name: null,
        email_id: null,
        sort: null,
        api_key: null,
        limit_per_day: null,
        is_active: false,
      },
      rules: {
        name: [
          v => !!v || "Name is required",
        ],
        email_id: [
          v => !!v || "Email id is required"
        ],
        sort: [
          v => !v ||  v>=1 || "Must be greater than or equals to 1",
          v => !v ||  v<=20 || "Must be less than or equals to 20",
        ],
        api_key: [
          v => !!v || "Api key is required",
        ],
        limit_per_day: [
        ],
        is_active: [
          v => !!v || "Is active is required",
        ],
      },
      users: [],
      isLoading: false,
      searchUser: '',
    };
  },
  watch: {
    searchUser: {
      handler: _.debounce(async function(val){
        try{
          if(val){
            this.isLoading = true
            let {data: {payload}} = await User.users(`search=${val}&self=true&page=1`)
            this.users = payload.rows
            this.isLoading = false
          }
        }catch(err){
          console.log(err)
          this.isLoading = true
        }
      }, 900),
      deep: true
    },
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Provider.create(ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Provider"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Provider" });
    }
  }
};
</script>