<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Api</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.api_name"
                    :rules="rules.api_name"
                    hint="Please enter name"
                    label="Api Name*"
                  ></v-text-field>

                  <v-select
                    class="mt-6"
                    v-model="form.provider_id"
                    :rules="rules.provider_id"
                    :items="providers"
                    item-text="name"
                    item-value="id"
                    label="Providers*"
                    dense
                  ></v-select>

                  <v-select
                    class="mt-6"
                    v-model="form.app_name"
                    :rules="rules.app_name"
                    :items="appTypes"
                    item-text="name"
                    item-value="name"
                    label="Application*"
                    dense
                  ></v-select>

                  <v-autocomplete
                    v-model="form.email_id"
                    :rules="rules.email_id"
                    :items="users"
                    :loading="isLoading"
                    :search-input.sync="searchUser"
                    hide-no-data
                    hide-selected
                    item-text="email"
                    item-value="email"
                    label="Email id*"
                    placeholder="Type email address"
                  ></v-autocomplete>

                  <div>
                    <div class="caption">Token*</div>
                    <div>{{form.token}} <v-btn icon @click.prevent="copyTokenToClipboard(form.token)"><v-icon>mdi-clipboard-outline</v-icon></v-btn></div>
                  </div>

                  <v-switch
                    class="mt-2"
                    v-model="form.is_active"
                    label="Is active"
                  ></v-switch>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash"
import Api from "@/api/api"
import User from '@/api/user'
import ProviderApi from '@/api/provider'
import {mapGetters} from "vuex"

export default {
  data() {
    return {
      valid: null,
      form: {
        api_name: null,
        provider_id: null,
        app_name: null,
        email_id: null,
        token: null,
        is_active: null,
      },
      rules: {
        api_name: [ v => !!v || "Api name is required"],
        provider_id: [v => !!v || "Api name is required"],
        app_name: [v => !!v || "App name is required"],
        email_id: [v => !!v || "Email id is required"]
      },
      appTypes: [],
      users: [],
      isLoading: false,
      searchUser: '',
    };
  },
  async mounted(){
    try{
      this.spinner(true)
      this.form = _.pick(this.vg_editObj, ["api_name", "provider_id", "app_name","email_id","token","is_active"])
      if(this.vg_editObj.email_id) this.users = [this.vg_editObj.email_id]
      let {data} = await User.app()
      this.appTypes = data.payload
      let providerList = await ProviderApi.list('items=-1&page=1')
      if(providerList?.data?.payload?.rows){
        this.providers = providerList.data.payload.rows
      }
      this.spinner(false)
    }catch(err){
      this.spinner(false)
    }
  },
  watch: {
    searchUser: {
      handler: _.debounce(async function(val){
        try{
          if(val){
            this.isLoading = true
            let {data: {payload}} = await User.users(`search=${val}&self=true&page=1`)
            this.users = payload.rows
            this.isLoading = false
          }
        }catch(err){
          console.log(err)
          this.isLoading = true
        }
      }, 900),
      deep: true
    },
  },
  computed: {
      ...mapGetters({
          vg_editObj: "api/editObj"
      })
  },
  methods: {
    copyTokenToClipboard(token){
      navigator.clipboard.writeText(token)
      this.snackbar({status: true, message: 'Copied to clipboard', color: 'success'})
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Api.update(this.vg_editObj.id, this.form)
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Api"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Api" });
    }
  }
};
</script>