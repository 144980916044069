<template>
  <v-dialog persistent width="500" :value="openUpload">
      <v-card>
        <v-card-title elevation="0" class="secondary">
            <v-toolbar-title class="white--text">Upload File</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click.prevent="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <form v-if="files.length===0">
            <v-card-text>
                <div 
                    id="drop-area" 
                    :class="{'active':dragging}" 
                    @dragenter.prevent="dragging=true" 
                    @dragover.prevent="dragging=true" 
                    @dragleave.prevent="dragging=false"
                    @drop.prevent="addFile"
                    draggable="false">
                    <v-container>
                        <v-row class="text-center">
                            <v-col cols="12" class="py-0">
                                <p>Drag File Here</p>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <input class="d-none" type="file" id="uploadFile" multiple @change="addFile" />
                                <v-btn :disabled="onProgress" tag="label" text color="primary" for="uploadFile">Select File</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-card-text>
        </form>
        <v-container v-else>
            <v-row>
                <v-col cols="3" sm="6" md="4" v-for="(eachFile, index) in files" :key="index">
                    <v-card>
                        <v-progress-linear v-if="eachFile.status.started" :value="eachFile.status.progress" :color="eachFile.status.completed ? 'success' : 'primary'" />
                        <v-icon style="font-size: 120px;" v-if="eachFile.upload.type==='application/pdf'">mdi-file-pdf</v-icon>
                        <v-img v-else :src="eachFile.upload.data" height="120" />
                        <v-card-text class="px-3 py-0"> 
                            <div class="text-truncate" v-text="eachFile.upload.name"></div>
                            <div class="text-truncate" v-text="readableFileSize(eachFile.upload.size)"></div>
                        </v-card-text>
                        <v-card-actions class="pt-0 mt-0">
                            <v-btn @click.prevent="removeFile(eachFile)" class="error" :disabled="onProgress || eachFile.status.completed" small block>Remove</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="3" sm="6" md="4">
                    <v-card>
                        <v-card-text>
                            <div 
                                id="drop-area" 
                                :class="{'active':dragging}" 
                                @dragenter.prevent="dragging=true" 
                                @dragover.prevent="dragging=true" 
                                @dragleave.prevent="dragging=false"
                                @drop.prevent="addFile">
                                <v-container>
                                    <v-row class="text-center">
                                        <v-col cols="12">
                                            <p>Drag File Here</p>
                                        </v-col>
                                        <v-col cols="12" class="pt-1 pb-2">
                                            <input class="d-none" type="file" id="uploadFile" multiple @change="addFile" />
                                            <v-btn x-small tag="label" text color="primary" for="uploadFile">Select File</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn @click.prevent="clear" :disabled="onProgress" class="primary float-right">Clear</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click.prevent="upload" :loading="onProgress" class="primary float-right">
                        <v-icon>mdi-upload</v-icon>
                        <span>Upload</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
      </v-card>
      
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import FileAPI from '../api'
export default {
    props: ['openUpload', 'selected'],
    data(){
        return {
            onProgress: false,
            dragging: false,
            files: [],
            validType: ['application/pdf', 'image/gif', 'image/jpeg', 'image/png', 'image/webp']
        }
    },
    methods: {
        addFile(e){
            if(this.onProgress) return
            this.clear()

            let fileList = e.target.files || e.dataTransfer.files
            let self = this
            for(const eachFile of fileList){
                this.loadFile(eachFile, fileData => {
                    if(fileData){
                        let fileInfo = {
                            upload: {
                                data: fileData,
                                file: eachFile.name,
                                name: this.name(eachFile.name),
                                ext: this.ext(eachFile.name),
                                type: eachFile.type,
                                size: eachFile.size,
                                path: self.selected
                            },
                            status: {
                                started: false,
                                completed: false,
                                progress: 0
                            }
                        };
                        self.files.push(fileInfo)
                    }
                })
            }
        },
        loadFile(file, callback){
            if(!_.includes(this.validType, file.type)) return

            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                callback(reader.result)
            }

        },
        upload(){
            let self = this
            let allPromise = this.files.map(async file => {
                if(!file.status.completed){
                    file.status.started = true
                    let res = await FileAPI.upload(
                        file.upload,
                        {onUploadProgress: e => {
                            self.calculateProgress(e, file)
                        }}
                    )  
                    file.status.completed = true
                    return res;
                }
            })
            Promise.all(allPromise).then(() => {
                self.snackbar({ status: true, message: `${allPromise.length} Files uploaded successfully` })
                this.uploaded()
            }).catch(err => {
                self.snackbar({status: true, message: err.response.data.message})
                console.log(err)
            })
        },
        name(path){
            if(!path) return
            return path.substring(path.lastIndexOf("/")+1, path.lastIndexOf("."))
        },
        ext(path){
            if(!path) return 
            return path.substring(path.lastIndexOf(".")+1)
        },
        calculateProgress(e, file){
           let progressed = Math.round((e.loaded * 100) / e.total)
            file.status.progress = progressed
        },
        clear(){
            this.files = []
        },
        close(){
            this.clear()
            this.$emit('closeUpload')
        },
        uploaded(){
            this.clear()
            this.$emit('change')
        },
        readableFileSize(size) {
            if(!size) return

            var i = Math.floor( Math.log(size) / Math.log(1024) )
            return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
        },
        removeFile(file){
            this.files =  _.reject(this.files, ['upload.name', file.upload.name])
        }
    }
}
</script>

<style scoped>
#drop-area{
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 100%;
    margin-top: 20px;
}
#drop-area.active{
    border-color: #999;
    background-color: #ccc;
}
</style>
        