<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Api</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" class="mt-4" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn v-if="role.trashes"
            class="ml-4 mt-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_api.rows"
          :loading="loading"
          :server-items-length="vg_api.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.is_active="{ item }">
            <v-chip small :color="color[item.is_active]" dark>{{
              (item.is_active)?'Active':'Inactive'
            }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon color="primary" class="ml-2 mr-1" @click.prevent="copyTokenToClipboard(item)"
              >mdi-clipboard-outline</v-icon
            >
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash"
import { mapGetters, mapMutations, mapActions } from "vuex";
import ApiApi from "@/api/api"
import VuetifyTable from "@/helper/table"
export default {
  data() {
    return {
      color: {
        true: "#66BB6A",
        false: "#EF5350",
      },
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Api Name", value: "api_name" },
        { text: "Provider Name", value: "provider.name", sortable: false },
        { text: "App Name", value: "app_name" },
        { text: "Email Id", value: "email_id" },
        { text: "Is Active", value: "is_active" },
        { text: "Actions", value: "action", sortable:false },
      ],
      search: ""
    };
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          await this.va_api(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    "vg_queryObject.search"(){
      this.options.page = 1
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Api")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_api: "api/list",
      vg_queryObject: "api/query"
    })
  },
  methods: {
    copyTokenToClipboard(item){
      navigator.clipboard.writeText(item.token)
      this.snackbar({status: true, message: 'Copied to clipboard', color: 'success'})
    },
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    createApi() {
      this.$router.push({ name: "ApiCreate" })
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "ApiEdit" })
    },
    trashedApi() {
      this.$router.push({ name: "ApiTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm("Are you sure to delete this?").then(async () => {
        try{
          self.spinner(true)
          let {data} = await ApiApi.delete(item.id)
          this.snackbar({status: true, message: data.message, color:"success"})
          await this.va_api(this.optionsToQueryString(this.vg_queryObject))
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "api/editObj",
      vm_queryObject: "api/query"
    }),
    ...mapActions({
      va_api: "api/list"
    })
  }
};
</script>
