import Vue from 'vue'
// import store from '@/store'

Vue.mixin({
    methods: {
        spinner(state) {
            this.$root.Spinner = state
        },
    
        snackbar(data) {
            if(typeof data === 'boolean'){
                this.$root.Snackbar.status = data
            }else{
                this.$root.Snackbar.timeout = data.timeout?data.timeout:2000
                this.$root.Snackbar.message = data.message
                this.$root.Snackbar.status = data.status
                this.$root.Snackbar.color = data.color?data.color:'black'
            }
        },

        confirm(message){
            return new Promise((resolve) => {
                this.$root.Confirm.message = message
                this.$root.Confirm.status = true
                document.addEventListener('click', (e) => {
                    if(e.target.innerHTML==='Ok' || (e.target.children[0] && e.target.children[0].innerHTML=='OK')){
                        resolve()
                    }
                })
            })
        }
    }
});