import Template from "@/views/template";
import TemplateCreate from "@/views/template/create"
import TemplateEdit from "@/views/template/edit"
import TemplateTrash from "@/views/template/trash"

export default [
    {
        name: "Template",
        path: "/template",
        component: Template,
        meta: {
            nav: {
                addToNav: true,
                navText: "Template",
                header: "Template",
                navIcon: "mdi-post",
            }
        },
    },
    {
        name: "TemplateCreate",
        path: "/template/create",
        component: TemplateCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Template > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "TemplateEdit",
        path: "/template/edit",
        component: TemplateEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Template > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "TemplateTrash",
        path: "/template/trash",
        component: TemplateTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Template > Trash",
                navIcon: "",
            }
        }
    }
]
